import {
    CONTACT_LIST_REQUEST,
    CONTACT_LIST_SUCCESS,
    CONTACT_LIST_FAIL,

    CONTACT_ADD_REQUEST,
    CONTACT_ADD_SUCCESS,
    CONTACT_ADD_FAIL,
    CONTACT_ADD_RESET,

    CONTACT_DELETE_REQUEST,
    CONTACT_DELETE_SUCCESS,
    CONTACT_DELETE_FAIL,
    CONTACT_DELETE_RESET,

    CONTACT_UPDATE_REQUEST,
    CONTACT_UPDATE_SUCCESS,
    CONTACT_UPDATE_FAIL,
    CONTACT_UPDATE_RESET
} from '../constants/contactConstants';

import axios from 'axios';

export const contactListAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONTACT_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.get(
            `/api/contact/get_contacts/`,
            config
        )

        dispatch({
            type: CONTACT_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CONTACT_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const contactAddAction = (contact) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONTACT_ADD_REQUEST,
        })

        let formData = new FormData();
        // Ajoutez chaque champ de l'objet candidature au formData
        for (const key in contact) {
            if (contact.hasOwnProperty(key)) {
                formData.append(key, contact[key]);
            }
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }

        const { data } = await axios.post(
            `/api/contact/add_contact/`,
            formData,
            config
        )

        dispatch({
            type: CONTACT_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CONTACT_ADD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const contactDeleteAction = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONTACT_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.delete(
            `/api/contact/delete_contact/${id}/`,
            config
        )

        dispatch({
            type: CONTACT_DELETE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CONTACT_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const contactUpdateAction = (contact_id, contact) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONTACT_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        
        const { data } = await axios.put(
            `/api/contact/update_contact/${contact_id}/`,
            contact,
            config
        )

        dispatch({
            type: CONTACT_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CONTACT_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const contactAddResetAction = () => (dispatch) => {
    dispatch({ type: CONTACT_ADD_RESET });
}

export const contactDeleteResetAction = () => (dispatch) => {
    dispatch({ type: CONTACT_DELETE_RESET });
}

export const contactUpdateResetAction = () => (dispatch) => {
    dispatch({ type: CONTACT_UPDATE_RESET });
}