import {
    CONTACT_LIST_REQUEST,
    CONTACT_LIST_SUCCESS,
    CONTACT_LIST_FAIL,

    CONTACT_ADD_REQUEST,
    CONTACT_ADD_SUCCESS,
    CONTACT_ADD_FAIL,
    CONTACT_ADD_RESET,

    CONTACT_DELETE_REQUEST,
    CONTACT_DELETE_SUCCESS,
    CONTACT_DELETE_FAIL,
    CONTACT_DELETE_RESET,

    CONTACT_UPDATE_REQUEST,
    CONTACT_UPDATE_SUCCESS,
    CONTACT_UPDATE_FAIL,
    CONTACT_UPDATE_RESET
} from '../constants/contactConstants';

// Compare this snippet from user-interface/src/redux/reducers/contactReducers.js:
export const contactListReducer = (state = { contacts: [] }, action) => {
    switch (action.type) {
        case CONTACT_LIST_REQUEST:
            return { loading: true, contacts: [] };
        case CONTACT_LIST_SUCCESS:
            return { loading: false, contacts: action.payload };
        case CONTACT_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/contactReducers.js:
export const contactAddReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTACT_ADD_REQUEST:
            return { loading: true };
        case CONTACT_ADD_SUCCESS:
            return { loading: false, success: true, contact: action.payload };
        case CONTACT_ADD_FAIL:
            return { loading: false, error: action.payload };
        case CONTACT_ADD_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/contactReducers.js:
export const contactDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTACT_DELETE_REQUEST:
            return { loading: true };
        case CONTACT_DELETE_SUCCESS:
            return { loading: false, success: true };
        case CONTACT_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case CONTACT_DELETE_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/contactReducers.js:
export const contactUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTACT_UPDATE_REQUEST:
            return { loading: true };
        case CONTACT_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case CONTACT_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case CONTACT_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}
