export const CONTACT_LIST_REQUEST = 'CONTACT_LIST_REQUEST';
export const CONTACT_LIST_SUCCESS = 'CONTACT_LIST_SUCCESS';
export const CONTACT_LIST_FAIL = 'CONTACT_LIST_FAIL';

export const CONTACT_ADD_REQUEST = 'CONTACT_ADD_REQUEST';
export const CONTACT_ADD_SUCCESS = 'CONTACT_ADD_SUCCESS';
export const CONTACT_ADD_FAIL = 'CONTACT_ADD_FAIL';
export const CONTACT_ADD_RESET = 'CONTACT_ADD_RESET';

export const CONTACT_DELETE_REQUEST = 'CONTACT_DELETE_REQUEST';
export const CONTACT_DELETE_SUCCESS = 'CONTACT_DELETE_SUCCESS';
export const CONTACT_DELETE_FAIL = 'CONTACT_DELETE_FAIL';
export const CONTACT_DELETE_RESET = 'CONTACT_DELETE_RESET';

export const CONTACT_UPDATE_REQUEST = 'CONTACT_UPDATE_REQUEST';
export const CONTACT_UPDATE_SUCCESS = 'CONTACT_UPDATE_SUCCESS';
export const CONTACT_UPDATE_FAIL = 'CONTACT_UPDATE_FAIL';
export const CONTACT_UPDATE_RESET = 'CONTACT_UPDATE_RESET';
