// src/components/Footer/Footer.js
import React from 'react';
import './Footer.css'; // Assurez-vous que le chemin est correct

function Footer() {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <p>© 2024 COODE-NT. Tous droits réservés.</p>
        <p>Une question ? Contactez-nous à <a href="mailto:contact@coode-nt.com">contact@coode-nt.com</a></p>
      </div>
    </footer>
  );
}

export default Footer;
