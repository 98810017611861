import React from 'react';
import './Modale.css'; // Assurez-vous d'avoir un fichier CSS pour les styles de votre modale

const Modale = ({ isOpen, onClose, title, children, type }) => {
  if (!isOpen) return null;

  return (
    <div className={`modale-backdrop`}>
      <div className={`modale ${type}`}>
        <div className="modale-header">
          <h5 className="modale-title">{type != 'info' && title}</h5>
          <button onClick={onClose} className="close-button">&times;</button>
        </div>
        <div className="modale-body">
          {children}
        </div>
      </div>
    </div>
  );
};


export default Modale;
