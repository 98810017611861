import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { login } from '../../redux/actions/userActions';

import './Connexion.css'; // Assurez-vous que le chemin vers le fichier CSS est correct

function Connexion() {
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        if (userInfo && userInfo != undefined && userInfo.isAdmin) {
            navigate('/');
        }

    }, [userInfo]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(login(email, password));
    };

    return (
        <div className="connexion-container">
            <form className="connexion-form" onSubmit={handleSubmit}>
                <h2>Connexion</h2>
                <div className="form-control">
                    <label htmlFor="identifiant">Identifiant</label>
                    <input
                        id="identifiant"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-control">
                    <label htmlFor="password">Mot de passe</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="connexion-btn">Se connecter</button>
            </form>
        </div>
    );
}

export default Connexion;
